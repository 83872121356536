
import { Options, Vue } from "vue-class-component";
import BackToInvoicesButton from "@/components/tuition-fees/BackToInvoicesButton.vue";
import MirroringUserBanner from "@/components/tuition-fees/MirroringUserBanner.vue";
import DownloadHelper from "@/helpers/tuitionfees/download-helper";
import UnauthorizedHelper from "@/helpers/tuitionfees/unauthorized-helper";
import { SessionStorageKeys } from "@/constants/constants";
import { TfPerson } from "@/interfaces/api";
import SessionStorageService from "@/services/sessionstorage-service";

@Options({
  components: { BackToInvoicesButton, MirroringUserBanner },
  data: () => ({
    /**
     * The current user object
     */
    currentUser: {},
    /**
     * The personal id of the user being mirrored by admin
     */
    personalId: "",
    /**
     * The name of the user being mirrored by admin
     */
    personName: "",
    /**
     * The lucat id of the user being mirrored by admin
     */
    lucatId: "",
    /**
     * A boolean to indicate if tfp-admin is mirroring user or not
     */
    isMirroringUser: false,
  }),
  computed: {},
  methods: {
    /**
     * Gets the personal id from the session storage
     */
    getUserId(): void {
      const mirroredUser: TfPerson | undefined = SessionStorageService.getItem(
        SessionStorageKeys.MirroredUser
      );

      if (mirroredUser) {
        this.personName = mirroredUser.displayName;
        this.personalId = mirroredUser.personalId;
        this.lucatId = mirroredUser.luEduPersonPrimaryId;
      }
    },
    /**
     * Downloads the specified invoice as a PDF file
     */
    async downloadInvoice() {
      const invoiceNo = this.$route.params.invoiceNo;
      await DownloadHelper.downloadInvoice(invoiceNo);
    },

    /**
     * If not logged in or not a student, redirect to unauthorized page
     * Otherwise, check if admin is mirroring a user, if so show banner
     * if not redirect to unauthorized page
     */
    checkIfUserIsStudentOrAdminMirroringUser(): void {
      this.currentUser = this.$store.getters.getCurrentUser;
      this.isMirroringUser =
        UnauthorizedHelper.checkIfUserIsStudentOrAdminMirroringUser(
          this.currentUser
        );
    },
    /**
     * Scrolls to the top of the page to solve a problem where the page is scrolled down
     * on load from when the user is positioned low on tuition fees page
     */
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getUserId();
    this.scrollToTop();
    this.checkIfUserIsStudentOrAdminMirroringUser();
  },
})
export default class BankTransfer extends Vue {}
