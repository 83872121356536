import SessionStorageService from "@/services/sessionstorage-service";
import { SessionStorageKeys } from "@/constants/constants";
import router from "@/router";
import { IUser } from "@/interfaces/IUser";

class UnauthorizedHelper {
  /**
   * The function checks if the current user is a student or an admin mirroring a user and returns a boolean value based on the conditions.
   * @param {IUser | undefined} currentUser - Represents the current user who is interacting with the system.
   * If the user is not logged in, the `currentUser` parameter wil be `undefined` otherwise it will contain the user's information.
   * @returns Returns a boolean value representing whether the current user is an admin mirroring a user.
   * If the current user is not logged in, the function will open a popup log in window to authorize the user.
   * If the current user is logged in but is not an admin mirroring a user or a student,
   * the function will redirect the user to the "Unauthorized" page.
   */
  checkIfUserIsStudentOrAdminMirroringUser(
    currentUser: IUser | undefined
  ): boolean {
    const isMirroringUser = SessionStorageService.getItem(
      SessionStorageKeys.IsMirroringUser
    );

    const isEmployee = currentUser
      ? currentUser.entitlements?.includes("Employee")
      : false;

    if (!isMirroringUser || isMirroringUser === "false") {
      if (!currentUser) {
        this.openLogin();
      } else if (isEmployee) {
        console.warn(
          "🚀 ~ UnauthorizedHelper ~ routing to unauthorized:",
          currentUser
        );

        this.routeToUnauthorized();
      }
    }

    return isMirroringUser === "true";
  }

  /**
   * Opens a a popup log in window to authorize the user.
   * It is called when the user is not logged in and tries to access a page that requires authentication.
   */
  private openLogin() {
    const url = `${process.env.VUE_APP_BASE_API_URL}authorize`;
    const popupParams =
      "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no";
    window.open(url, "Login", popupParams);
  }

  /**
   * Redirects the user to the "Unauthorized" page.
   * It is called when the user is logged in but is not a student and tries to access a page that requires student privileges.
   */
  private routeToUnauthorized() {
    router.push({
      name: "Unauthorized",
    });
  }
}

export default new UnauthorizedHelper();
